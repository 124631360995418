import { AxiosResponse } from "axios";
import { httpClientTD } from "./ApiClient";

export const LOGIN = "/login";
export const GET_ETAS = "/etas";
export const UPLOAD_ETAS = "/etas/upload";
export const DOWNLOAD_ETA = "/etas";
export const DELETE_ETA = "/etas";
export const LIST_USER = "/users";
export const LIST_DRIVER = "/drivers";
export const SEARCH_DRIVER = "/search/driver";
export const UPLOAD_STORE_MASTER = "/stores/master/upload";
export const SEARCH_STORE_MASTER = "/search/stores/master";
export const SEARCH_SEND_SMS_STATUS = "/search/sendsms/status";
export const RESEND_SMS_TO_STORE_OWNER = "/stores/resend-sms";
export const ENABLE_SEND_SMS = "/stores/master/enable-send-sms";
export const DISABLE_SEND_SMS = "/stores/master/disable-send-sms";
export const ENABLE_ALL_SEND_SMS = "/stores/master/enable-all-send-sms";
export const DISABLE_ALL_SEND_SMS = "/stores/master/disable-all-send-sms";
export const SENT_EMAIL_TO_RESET_PASSWORD = "/users/reset-password";
export const SENT_EMAIL_TO_FORGOT_PASSWORD = "/users/forgot-password";
export const DASHBOARD = "/dashboard";
export const DOWNLOAD_TRIPS = "/trips/download";
export const SEARCH_EPOD_REPORT = "v2/search/epod-report";
export const GEN_SIGNED_URL = "v2/generate-sined-url";
export const DOWNLOAD_EPOD_REPORT = "v2/search/epod-report/download";
export const ENABELED_ONE_FRONT = "/feature-flags";
export const GET_ETA = "/v2/eta";
export const GET_DC_INFORMATION = "/v2/dc";
export const TOTAL_TASK = "v2/tasks/total";
export const GET_FEATURES_FLAGS = "v2/configuration/features-flags";
export const DOWNLOAD_RECEIVED_ORDER_REPORT = "/v2/received-order/report";

export const ACTIVATE_USER = (uid: string) => {
  return `/users/${uid}/activate`;
};

export const DEACTIVATE_USER = (uid: string) => {
  return `/users/${uid}/deactivate`;
};

export const USER_PERMISSIONS = (uid: string) => {
  return `/v2/users/${uid}/permissions`;
};

export const UPDATE_TRACKING_STATUS = (trackingStatusID: string) => {
  return `/drivers/tracking/status/${trackingStatusID}`;
};

export const SEND_SMS_TO_STORE_OWNER = (dcCode: string) => {
  return `/etas/${dcCode}/sendsms`;
};

export const SEND_LINE_MESSAGE = (dcCode: string) => {
  return `/etas/${dcCode}/sendline`;
};

export type GetDriverLocationParams = {
  trackingDriverID: string;
};

export const GET_DRIVER_LOCATION = (params: GetDriverLocationParams) => {
  return `/drivers/tracking/${params.trackingDriverID}`;
};

export const EDIT_TRACKING_DRIVER = (params: string) => {
  return `/drivers/tracking/${params}`;
};

export const RE_ORDER_STOP = (params: string) => {
  return `/drivers/tracking/${params}/reorder-stop`;
};

export const GET_REPORT_DETAIL = (tracking_driver_id: string, store_number: string) => {
  return `/v2/trips/${tracking_driver_id}/stores/${store_number}`;
};

export const STORE_PROGRESS = (trip_id: string) => {
  return `/v2/trips/${trip_id}/store-progress`;
};

export const REPORT_ISSUE = (task_id: string) => {
  return `/v2/tasks/${task_id}/report-issue`;
};
export const MARK_READ_REPORT = (trip_id: string) => {
  return `/v2/tasks/${trip_id}/mark-read`;
};

export const CANCEL_ISSUE = (trip_id: string) => {
  return `/v2/tasks/${trip_id}/cancel-issue`;
};

export const DOWNLOAD_TASK_STATUS_REPORT = (plannedArrivalDate: string) => {
  return `/v2/drivers/report/${plannedArrivalDate}`;
};

export const DOWNLOAD_SPEED_LIMIT_REPORT = (plannedArrivalDate: string) => {
  return `/v2/drivers/speed-limit/report/${plannedArrivalDate}/download`;
};

export const GET_LOCATION_HISTORY = (trip_id: string) => {
  return `/v2/trips/${trip_id}/location-history`;
};

export const DELETE_USER = (uid: string) => {
  return `v2/users/${uid}`;
};

export const NEW_EDIT_TRACKING_DRIVER = (params: string) => {
  return `/v2/trips/${params}`;
};

export const getSubbyList = (): Promise<AxiosResponse<{ uid: string; name: string }[]>> =>
  httpClientTD.get("/v2/subby").then((res) => res);
