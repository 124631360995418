export const truckTypeLists = [
  { label: "ทั้งหมด", value: "all" },
  { label: "10W", value: "10W" },
  { label: "10WT", value: "10WT" },
  { label: "6W", value: "6W" },
  { label: "6WT", value: "6WT" },
  { label: "4WCR", value: "4WCR" },
  { label: "4WJ", value: "4WJ" },
  { label: "4W", value: "4W" },
];

export const bookingTruckTypeLists = [
  { label: "ทั้งหมด", value: "all" },
  { label: "4W", value: "4W" },
  { label: "4WJB", value: "4WJB" },
  { label: "4WCR", value: "4WCR" },
  { label: "4WJCR", value: "4WJCR" },
  { label: "6W", value: "6W" },
  { label: "6W 5.5", value: "6W 5.5" },
  { label: "6W 7.2", value: "6W 7.2" },
  { label: "6WT", value: "6WT" },
  { label: "6WCR", value: "6WCR" },
  { label: "10W", value: "10W" },
  { label: "10WT", value: "10WT" },
  { label: "10WCR", value: "10WCR" },
  { label: "12W", value: "12W" },
  { label: "TR", value: "TR" },
];
