import { Form, Select, Tag } from "antd";
import { Form as FromDSInstance } from "design-system";
import type { CustomTagProps, DisplayValueType } from "rc-select/lib/BaseSelect";

interface MultipleSelectProps {
  selectedValues?: any[];
  showSearch?: boolean | undefined;
  fieldName?: string;
  className?: string;
  options: { label: string; value: any }[];
  style?: React.CSSProperties;
  onChange?: (value: any, option: { label: string; value: any } | { label: string; value: any }[]) => void;
  optionFilterProp?: string;
  "data-testid"?: string;
}

const MultipleSelectComponent = ({
  options = [],
  selectedValues = [],
  fieldName = "",
  ...props
}: MultipleSelectProps) => {
  const formAnt = Form.useFormInstance();
  const formDS = FromDSInstance.useFormInstance();
  const allSelectTagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <>
        {label === "ทั้งหมด" ? (
          <div style={{ paddingLeft: 6 }}>
            <span> ทั้งหมด</span>
          </div>
        ) : (
          <Tag
            color="default"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
          >
            {label}
          </Tag>
        )}
      </>
    );
  };

  const maxTagCountRender = () => {
    const form = formAnt || formDS;
    const selectValues = form.getFieldValue(fieldName) || selectedValues;
    return selectValues.length === options.length ? 0 : "responsive";
  };

  const maxTagPlaceholderRender = (value: DisplayValueType[]) => {
    const form = formAnt || formDS;
    const selectValues = form.getFieldValue(fieldName) || selectedValues;
    if (selectValues.length === options.length) {
      return "ทั้งหมด";
    } else {
      return `+${value.length}`;
    }
  };
  return (
    <Select
      mode="multiple"
      placeholder={<span style={{ color: "black" }}>ทั้งหมด</span>}
      allowClear={true}
      maxTagPlaceholder={maxTagPlaceholderRender}
      tagRender={allSelectTagRender}
      maxTagCount={maxTagCountRender()}
      options={options}
      {...props}
    />
  );
};

export default MultipleSelectComponent;
