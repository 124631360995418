import { FormDs, Form, SelectDs, ButtonDs } from "design-system";
import { dcLists } from "../../../utils/DClists";
import { Alert, Space, notification } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import AlertMessage from "../../../shared/component/AlertMessage";
import ReturnEtaRequestService from "../services/return-eta-request-service";
import { AxiosError } from "axios";
import { NotificationComponent } from "../../../shared/component/NotificationComponent";
import MultipleSelectComponent from "../../../shared/component/MultipleSelectComponent/MultipleSelectComponent";

interface Props {
  onRequest: () => void;
}

const FormRequest = ({ onRequest }: Props) => {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<{ title: string; description: string } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const [submittable, setSubmittable] = useState<boolean>(false);
  const truckTypes = ["10W", "10WT", "10WCR", "6W", "6WT", "6WCR", "4WCR", "4WJ", "4WJCR", "4W"];

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const onFinish = (values: any) => {
    const dcCode = values.dc_code;
    const truckTypesVal =
      values.truck_types.length === truckTypes.length || values.truck_types.length === 0
        ? []
        : (values.truck_types as string[]).sort(sortTruckTypes);

    setIsLoading(true);
    setErrorMessage(null);
    ReturnEtaRequestService.createRturnEtaRequest({ dc_code: dcCode, truck_types: truckTypesVal }).then(
      (res) => {
        console.log(res);
        setIsLoading(false);
        openNotiSuccess();
        onRequest();
      },
      (error: AxiosError) => {
        console.log(error.response);
        if (error.response?.status === 409) {
          setErrorMessage({
            title: "ไม่สามารถส่งคำขอได้",
            description: "เนื่องจากคำขอซ้ำกับที่กำลังดำเนินการอยู่",
          });
        } else {
          setErrorMessage({
            title: "เกิดข้อผิดพลาด",
            description: "ไม่สามารถส่งคำขอได้ กรุณาลองใหม่อีกครั้ง",
          });
        }
        setIsLoading(false);
      },
    );
  };

  const sortTruckTypes = (a: string, b: string) => {
    const numA = parseInt(a, 10);
    const numB = parseInt(b, 10);
    if (numA !== numB) {
      return numB - numA;
    }
    const strA = a.replace(/\d+/g, "");
    const strB = b.replace(/\d+/g, "");
    return strA.localeCompare(strB);
  };

  const openNotiSuccess = () => {
    NotificationComponent({
      notification: notificationComponent,
      type: "success",
      topic: "ส่งคำขอสำเร็จ",
      message: "ไฟล์จะพร้อมให้ดาวน์โหลดได้ภายใน 15 นาที หรืออาจเร็วกว่านั้น",
    });
  };

  return (
    <>
      <FormDs
        style={{ display: "flex" }}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
        initialValues={{
          truck_types: [],
        }}
      >
        <Form.Item
          label="ศูนย์กระจายสินค้า"
          name="dc_code"
          rules={[{ required: true, message: "กรุณาเลือกศูนย์กระจายสินค้า" }]}
        >
          <SelectDs
            options={[...dcLists()]}
            placeholder="เลือกศูนย์กระจายสินค้า"
            style={{ width: 256 }}
            data-test-id="form_request_dc_code"
          />
        </Form.Item>
        <Form.Item label="ประเภทรถ" name="truck_types">
          <MultipleSelectComponent
            options={[...truckTypes.map((type) => ({ label: type, value: type }))]}
            className="select-ds"
            fieldName="truck_types"
            data-testid="form_request_truck_type"
            style={{ width: 256 }}
          />
        </Form.Item>
        <Form.Item>
          <ButtonDs
            type="primary"
            htmlType="submit"
            style={{ top: "27px" }}
            loading={isLoading}
            disabled={!submittable}
          >
            ส่งคำขอ
          </ButtonDs>
        </Form.Item>
      </FormDs>
      {errorMessage && (
        <AlertMessage
          type="error"
          message={errorMessage.title}
          description={errorMessage.description}
          margin="0 0 16px 0"
        />
      )}
      {!errorMessage && (
        <Alert
          message={
            <div style={{ display: "flex" }}>
              <Space>
                <InfoCircleOutlined />
                <div>หลังจากส่งคำขอแล้ว ไฟล์จะพร้อมให้ดาวน์โหลดได้ภายใน 15 นาที หรืออาจเร็วกว่านั้น</div>
              </Space>
            </div>
          }
          type="info"
        />
      )}
      {contextHolderNoti}
    </>
  );
};

export default FormRequest;
