import { FormDs, Form, SelectDs } from "design-system";
import { dcLists } from "../../../utils/DClists";
import { ReturnEtaDocument } from "../models/return-eta-request.model";
import { useEffect, useState } from "react";
import MultipleSelectComponent from "../../../shared/component/MultipleSelectComponent/MultipleSelectComponent";

interface Props {
  dataSource: ReturnEtaDocument[];
  onSearch: (data: ReturnEtaDocument[]) => void;
}

const FormFilter = ({ dataSource, onSearch }: Props) => {
  const [form] = Form.useForm();
  const [dcCode, setDcCode] = useState<string>("all");
  const truckTypes = ["10W", "10WT", "10WCR", "6W", "6WT", "6WCR", "4WCR", "4WJ", "4WJCR", "4W"];
  const [truckType, setTruckType] = useState<string[]>(truckTypes);

  useEffect(() => {
    if (dataSource.length > 0) {
      const isAllTruckSelected = truckType.length === truckTypes.length || truckType.length === 0;
      const filteredData = dataSource.filter((data) => {
        if (dcCode === "all" && isAllTruckSelected) {
          return true;
        } else if (dcCode !== "all" && isAllTruckSelected) {
          return data.dc_code === dcCode;
        } else if (dcCode === "all" && truckType.length !== truckTypes.length) {
          return truckType.some(
            (selectedTruck) =>
              data.truck_types?.some((truck) => truck === selectedTruck) || !data.truck_types,
          );
        } else if (dcCode === "all" && truckType.length === 0) {
          return true;
        } else {
          return (
            data.dc_code === dcCode &&
            truckType.some(
              (selectedTruck) =>
                data.truck_types?.some((truck) => truck === selectedTruck) || !data.truck_types,
            )
          );
        }
      });
      onSearch(filteredData);
    }
  }, [dcCode, truckType, dataSource]);

  return (
    <>
      <FormDs
        style={{ display: "flex" }}
        form={form}
        initialValues={{ dc_code: "all", truck_type: [] }}
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item label="ศูนย์กระจายสินค้า" name="dc_code">
          <SelectDs
            options={[{ label: "ทั้งหมด", value: "all" }, ...dcLists()]}
            style={{ width: 256 }}
            onChange={(value) => setDcCode(value)}
            data-test-id="form_filter_dc_code"
          />
        </Form.Item>
        <Form.Item label="ประเภทรถ" name="truck_type">
          <MultipleSelectComponent
            data-testid="form_filter_truck_type"
            className="select-ds"
            selectedValues={truckType}
            options={truckTypes.map((truckType) => ({ label: truckType, value: truckType }))}
            style={{ width: 256 }}
            onChange={(value) => setTruckType(value)}
          />
        </Form.Item>
      </FormDs>
    </>
  );
};

export default FormFilter;
