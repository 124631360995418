import { Alert, Checkbox, Popover, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { ReturnEtaDocument } from "../models/return-eta-request.model";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ButtonDs } from "design-system";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import LocalstorageService from "../../../shared/service/Localstorage.service";
import ReturnEtaRequestService from "../services/return-eta-request-service";

interface Props {
  dataSource: ReturnEtaDocument[];
  isLoading: boolean;
}

const DataTable = ({ dataSource, isLoading }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataDisplay, setDataDisplay] = useState<ReturnEtaDocument[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const userAuthInfoData = LocalstorageService.getUserAuthInfo();
  const [curentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    setCurrentPage(1);
    filterCreateByChecked(isChecked);
    setCurrentDate(new Date());
  }, [isLoading]);

  const columns: ColumnsType<ReturnEtaDocument> = [
    {
      title: "ลำดับ",
      fixed: "left",
      render: (text, record, index) => {
        return <div>{(currentPage - 1) * 15 + index + 1}</div>;
      },
    },
    {
      title: "ศูนย์กระจายสินค้า",
      dataIndex: "dc_code",
      key: "dc_code",
      fixed: "left",
    },
    {
      title: "ประเภทรถ",
      dataIndex: "truck_types",
      key: "truck_types",
      fixed: "left",
      render: (text: string[]) => {
        return (
          <>
            {!text ? (
              "ทั้งหมด"
            ) : (
              <Popover
                content={<span style={{ padding: "5px 5px 5px 5px" }}>{text?.concat().join(", ")}</span>}
              >
                <div style={{ display: "inline-flex" }}>
                  <div>{text.length > 1 ? text[0] + ", ..." : text[0]}</div>
                </div>
              </Popover>
            )}
          </>
        );
      },
    },
    {
      title: "เวลาส่งคำขอ",
      dataIndex: "requested_at",
      key: "requested_at",
      render: (text: string) => {
        return <>{text && text.length > 0 ? dayjs(text).format("DD-MM-YYYY, HH:mm:ss") : "-"}</>;
      },
    },
    {
      title: "ผู้ส่งคำขอ",
      render: (_, record) => {
        return <div>{record.requested_by.name}</div>;
      },
    },
    {
      title: "สถานะคำขอ",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return (
          <>
            {record.status.toLocaleLowerCase() === "fail" && <Tag color="error">Fail</Tag>}
            {record.status.toLocaleLowerCase() === "processing" && <Tag>Processing</Tag>}
            {record.status.toLocaleLowerCase() === "complete" && <Tag color="success">Complete</Tag>}
          </>
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <ButtonDs
            type="primary"
            disabled={record.status !== "complete"}
            onClick={() => onDownloadFile(record.id)}
          >
            Download
          </ButtonDs>
        );
      },
    },
  ];

  const onDownloadFile = (id: string) => {
    ReturnEtaRequestService.downloadReturnEtaFile(id).then(
      (res) => {
        const filename = res.headers["content-disposition"].split(";")[1].split("=")[1];
        createFile(filename, res.data);
      },
      (error) => {
        console.log("error", error);
      },
    );
  };

  const createFile = (fileName: string, data: Blob) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = URL.createObjectURL(data);
    link.setAttribute("download", decodeURI(fileName));
    link.click();
  };

  const filterCreateByChecked = (isChecked: boolean) => {
    setCurrentPage(1);
    if (isChecked && userAuthInfoData) {
      console.log("userAuthInfoData", userAuthInfoData);
      setDataDisplay(dataSource.filter((item) => item.requested_by.email === userAuthInfoData.email));
    } else {
      setDataDisplay(dataSource);
    }
  };

  return (
    <div>
      <Checkbox
        disabled={isLoading}
        onChange={(e) => {
          setIsChecked(e.target.checked);
          filterCreateByChecked(e.target.checked);
        }}
      >
        แสดงเฉพาะคำขอของฉัน
      </Checkbox>
      <div className="last-update-text">Last update : {dayjs(curentDate).format("DD-MM-YYYY, HH:mm:ss")}</div>
      <Table
        className="table-custom-design-system"
        loading={isLoading}
        columns={columns}
        dataSource={dataDisplay}
        rowKey={(row) => row.id}
        pagination={{
          current: currentPage,
          defaultPageSize: 15,
          total: dataDisplay.length,
          onChange: (page) => {
            setCurrentPage(page);
          },
        }}
        scroll={{ x: "max-content" }}
      />
      <Alert
        style={{ marginTop: 16 }}
        message={
          <div style={{ display: "flex" }}>
            <Space>
              <InfoCircleOutlined />
              <div>รายการคำขอข้อมูล จะแสดงเฉพาะคำขอข้อมูลในช่วง 24 ชั่วโมงที่ผ่านมา</div>
            </Space>
          </div>
        }
        type="info"
      />
    </div>
  );
};

export default DataTable;
